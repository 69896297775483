<template>
  <div class="pbf">
    <BrandTabHarvests :inurl="`/${props.type}/${props.id}/harvests`"/>
  </div>
</template>

<script setup lang="ts">
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  },
  type: {
    type: String
  }
})
</script>